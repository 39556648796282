import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';

//Import Scrollbar
import SimpleBar from "simplebar-react"

import { exportMenu } from './menues/menu'

import { exportInfoMenu } from './menues/infoMenu'

function SidebarContent() {
	const { t } = useTranslation();
	const ref = useRef();
	const location = useLocation();

	const [menu, setMenu] = useState(localStorage.getItem('role') === 'ROLE_INFO' ? exportInfoMenu : exportMenu)
	//const [navTabKey, setNavTabKey] = useState('link_1')

	function setActiveMenu() {
		for (let i = 0; i < menu.length; i++) {

			if (location.pathname.includes(menu[i].url)) {
				showItem(i)
			}
			if (location.pathname.includes(menu[i].url)) {
				if (menu[i].children?.length > 0) {
					showItem(i)
					break;
				}
			}

		}
	}

	const authorities = JSON.parse(localStorage.getItem('authorities')) || [];
	const isManager = authorities.includes('ROLE_MANAGER');

	function showItem(index) {
		let copy = [...menu];
		let copyItem = { ...menu[index] };
		copyItem.active = !copyItem.active;
		copy[index] = copyItem;
		setMenu(copy);
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{menu.filter(item => !isManager || !item.adminOnly).map((item, index) => (
							<li className={item.active ? "mm-active" : ""} key={index}>
								<Link to={item.url} className={"waves-effect " + (item.active ? "active" : "")}>
									<i className={item.icon}></i>
									<span>{t(item.i18n)}</span>
								</Link>
							</li>
						))
						}
					</ul>
				</div>

			</SimpleBar>
		</>
	)
}

export default SidebarContent