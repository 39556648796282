import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'

import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { PGET, GET, PUT, POST } from 'helpers/api'
import { formatDate, formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const URL = '/services/admin/api/pos-pageList'
	const { t } = useTranslation()

	const paymentTypes = [
		{ 'id': 1, 'name': t('CASH') },
		{ 'id': 2, 'name': t('TRANSFER') },
		{ 'id': 3, 'name': t('MONEY_TRANSFER') },
	]
	const tariffs = [
		{ "id": 1, "name": "START", "value": 150000 },
		{ "id": 2, "name": "STANDART", "value": 300000 },
		{ "id": 3, "name": "PREMIUM", "value": 700000 }
	]

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [modalPayment, setModalPayment] = useState(false)
	const [modalPaymentHistory, setModalPaymentHistory] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [data, setData] = useState([])
	const [payments, setPayments] = useState([])

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.posId = itemCopy.id
		itemCopy.amountIn = ""
		itemCopy.paymentMethod = 1
		itemCopy.note = ""
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		sendData.posId = selectedItemCopy.posId
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.paymentMethod = selectedItemCopy.paymentMethod
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/admin/api/pos-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.posId = null
			selectedItemCopy.amountIn = ""
			selectedItemCopy.paymentMethod = 2
			selectedItemCopy.note = ''
		}

		if (!selectedItem?.id) {
			toast.warning(t('NO_POS_SELECTED'))
			return
		}

		setModalPayment(bool)
	}

	async function showPaymentHistoryModal(bool, posId) {
		if (!bool) {
			setModalPaymentHistory(bool)
			return;
		}
		const response = await GET('/services/admin/api/pos-payment-history/' + posId, {}, { loader: true })
		setPayments(response)
		setModalPaymentHistory(bool)
	}

	async function handleDocumentsInCreate(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			id: dataCopy[index]['id'],
			activated: e.target.checked,
		}
		await PUT('/services/admin/api/pos-activate', sendData, { loader: true })
	}

	async function getData() {
		const response = await PGET('/services/admin/api/pos-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/pos-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('POSES')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('ID')}</th>
									<th>{t('OWNER')}</th>
									<th>{t('SALE_POINT_NAME')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('TARIFF')}</th>
									<th className="text-center">{t('TARIFF_AMOUNT')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle ' + ((selectedItem && selectedItem.id === item.id) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>{item.rowNum}</td>
										<td>{item.id}</td>
										<td>{item.ownerLogin}</td>
										<td>{item.name}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{tariffs.find(element => element.id === item.tariffId)?.name}</td>
										<td className="text-center">{formatMoney(item.tariff)}</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => handleDocumentsInCreate(e, index)} />
												</div>
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="d-flex justify-content-center me-2">
													<Link className="table-action-button table-action-primary-button"
														to={'/pos-update/' + item.id}>
														<i className="uil-edit-alt"></i>
													</Link>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('OWNER')}</span>
						<span>{selectedItem.ownerLogin}</span>
					</div>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('POS')}</span>
						<span>{selectedItem.name}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentMethod)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentMethod': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amountIn}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* PAYMENT */}

			{/* PAYMENT HISTORY */}
			<Modal show={modalPaymentHistory} animation={false} centered size="lg"
				dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentHistoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('HISTORY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('TYPE')}</th>
									<th>{t('NOTE')}</th>
									<th>Создал</th>
									<th>Оплата</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{payments.map((item, index) => (
									<tr key={index}>
										<td>{item.paymentTypeName}</td>
										<td>{item.note}</td>
										<td>{item.createdBy}</td>
										<td>
											<div className="text-nowrap">
												{formatMoney(item.amountIn)}
											</div>
										</td>
										<td>
											<div className="text-nowrap">
												{formatDateWithTime(item.createdDate)}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* PAYMENT HISTORY */}
		</>
	)
}

export default Index
