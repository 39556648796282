import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { POST } from 'helpers/api'


function Settings() {
	const { t } = useTranslation();
	const reduxSettings = useSelector(state => state.settings.settings)
	const [login, setLogin] = useState("")
	const [settings, setSettings] = useState({
		'loyalty': false,
		'inStore': false,
		'originalPrice': false,
		'multikassaOfd': false,
	})


	function saveSettings() {
		var sendData = {
			'settings': JSON.stringify(settings),
			'login': login
		}
		POST("/services/admin/api/user-settings", sendData).then(() => {
			toast.success(t('saved_successfully'))
		})
	}

	useEffect(() => {
		var reduxSettingsCopy = { ...reduxSettings }
		setSettings(reduxSettingsCopy)
	}, [reduxSettings])

	return (
		<>
			<div className="pt-40 relative">
				<div className="h-table-settings pr-2 mb-1">
					<div className="py-1">
						<div className="card mb-3">
							<div className="card-body">
								<div className="d-flex justify-content-between">
									<h5 className="settings-title">{t('CASHBOX_SETTINGS')}</h5><br></br>
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">{t('LOGIN')}</h6>
									</div>
									<div className="vertical-center w-250px">
										<input type="text" className="form-control"
											onChange={(e) => setLogin(e.target.value )} />
									</div>
								</div>
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">{t('LOYALTY')}</h6>
									</div>
									<div className="vertical-center">
										<input type="checkbox" className="ios-switch light" checked={settings.loyalty}
											onChange={(e) => setSettings({ ...settings, 'loyalty': e.target.checked })} />
									</div>
								</div>
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">{t('AMOUNT_OF_RESIDUES')}</h6>
									</div>
									<div className="vertical-center">
										<input type="checkbox" className="ios-switch light" checked={settings.inStore}
											onChange={(e) => setSettings({ ...settings, 'inStore': e.target.checked })} />
									</div>
								</div>

								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">{t('ADMISSION_PRICE')}</h6>
									</div>
									<div className="vertical-center">
										<input type="checkbox" className="ios-switch light" checked={settings.originalPrice}
											onChange={(e) => setSettings({ ...settings, 'originalPrice': e.target.checked })} />
									</div>
								</div>

								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">Multikassa OFD</h6>
									</div>
									<div className="vertical-center">
										<input type="checkbox" className="ios-switch light" checked={settings.multikassaOfd}
											onChange={(e) => setSettings({ ...settings, 'multikassaOfd': e.target.checked })} />
									</div>
								</div>
								
							</div>
						</div>

						
					</div>
				</div>
				<div className="d-flex">
					<button button="submit" className="btn btn-primary text-uppercase" onClick={saveSettings}>{t('SAVE')}</button>
				</div>
			</div>
		</>
	)
}

export default Settings
